import { useParamPage } from '@integration-layer/composables/useGAEvent/useDatalayerSetup/useParamPage'
import type { PageHierarchyNode } from '@integration-layer/ampliance/schemas/page-hierarchy-node-schema.localized'

export const useGAHierarchyPage = (data: PageHierarchyNode) => {
  // @ts-ignore
  const brand = data.content[0].brand as string
  if (!data.schema) {
    return
  }
  const schemaArraySplitted = data.schema.split('/')
  const amplienceSchema = schemaArraySplitted[schemaArraySplitted.length - 1]
  // @ts-ignore
  const gender = data.content[0].urlSlug as string
  // @ts-ignore
  const categories = data.content[0]?.categoryId?.split('>').slice(2) ?? ['']
  useParamPage('ampliencePage', {
    pageType: amplienceSchema,
    brand,
    gender,
    categories,
  })
}
